@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --font-sans: theme(fontFamily.sans);

    --color-text: theme(colors.blue.on.DEFAULT);

    --toastify-color-info: theme(colors.info.bg.light);
    --toastify-color-success: theme(colors.success.bg.light);
    --toastify-color-warning: theme(colors.warning.bg.light);
    --toastify-color-error: theme(colors.danger.bg.light);
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-z-index: 9999;

    --toastify-icon-color-info: theme(colors.info.on.stronger);
    --toastify-icon-color-success: theme(colors.success.on.stronger);
    --toastify-icon-color-warning: theme(colors.warning.on.stronger);
    --toastify-icon-color-error: theme(colors.danger.on.stronger);

    --toastify-text-color-info: theme(colors.info.on.stronger);
    --toastify-text-color-success: theme(colors.success.on.stronger);
    --toastify-text-color-warning: theme(colors.warning.on.stronger);
    --toastify-text-color-error: theme(colors.danger.on.stronger);

    --toastify-font-family: Poppins;
  }

  body {
    font-family: var(--font-sans);
    color: var(--color-text);
  }
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
